import React from 'react'
import styled from '@emotion/native'
import { UX_ASSETS } from '~/const/assets'
import Header from '~/components/partials/Header'
import Footer from '~/components/partials/Footer'
import Gradient from '~/components/stateless/Gradient'
import { VIEW, COLOR } from '~/const'

export default function Lobby({
    children,
    title,
    back = VIEW.HOME,
    footer = false,
    bgpattern = 'bg-weapons.png',
    bglinear = false,
    bgcolors = [
        [COLOR.BLUE, 0],
        [COLOR.BLUEBLACK, 1],
    ],
}) {
    return (
        <Container>
            {/* <Background1 source={UX_ASSETS['bg-blue.png']}></Background1> */}
            <Background1>
                <Gradient colors={bgcolors} linear={bglinear} />
            </Background1>
            <Background2
                source={UX_ASSETS[bgpattern]}
                imageStyle={{ resizeMode: 'repeat' }}
            >
                <Content>
                    <Header title={title} back={back} />
                    <Middle>{children}</Middle>
                    {footer && <Footer />}
                </Content>
            </Background2>
        </Container>
    )
}

const Container = styled.View`
    width: 100%;
    height: 100%;
`

const Background1 = styled.View`
    width: 100%;
    height: 100%;
`

const Background2 = styled.ImageBackground`
    width: 100%;
    height: 100%;
    position: absolute;
`

const Content = styled.View`
    justify-content: space-between;
    flex: 1;
`

const Middle = styled.View`
    flex: 1;
`
