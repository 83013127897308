import styled from '@emotion/native'
import { px } from '~/utils/device'
import { COLOR } from '~/const'

export const TabsHeader = styled.View`
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`

export const Tabs = styled.View`
    flex-direction: row;
`
export const Tab = styled.TouchableOpacity`
    background: ${(p) => (p.selected ? COLOR.BEIGE : COLOR.BROWNBLACK)};
    align-items: center;
    margin-right: ${px(20)};
    border-radius: ${px(50)};
    padding: ${px(12)} ${px(40)};
`
export const TabText = styled.Text`
    font-family: Poppins-Bold;
    font-size: ${px(28)};
    color: ${(p) => (p.selected ? COLOR.BROWNDARK : COLOR.BEIGE)};
`

export const TabsInfo = styled.View`
    background: ${COLOR.BROWNBLACK + '55'};
    border: ${px(5)} solid ${COLOR.BROWNBLACK};
    align-items: center;
    border-radius: ${px(50)};
    padding: ${px(10)} ${px(40)};
`
export const TabsInfoText = styled.Text`
    font-family: Poppins-Medium;
    font-size: ${px(20)};
    color: ${COLOR.BEIGE};
`
