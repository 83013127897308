import React from 'react'
import styled from '@emotion/native'
import { GAME_TYPE, GAME_SIZE } from 'conkis-core'
import { VIEW, COLOR } from '~/const'
import { UX_ASSETS } from '~/const/assets'
import { ERROR } from '~/const/errors'
import { changeView } from '~/store'
import { useGlobalState, useLocalState } from '~/store/hooks'
import { px } from '~/utils/device'
import { Server } from '~/server'
import { translate } from '~/locale'
import { AUDIO } from '~/audio'
import Lobby from '~/components/partials/Lobby'
import Counter from '~/components/stateless/Counter'
import Invite from '~/components/dialogs/Invite'
import Button from '~/components/stateless/Button'

export default function Play() {
    const [{ user }, setGlobalState] = useGlobalState('user.trophies')
    const [state, setState] = useLocalState({ inviting: false })

    async function onSearch() {
        try {
            await Server.partyCreate({
                game_type: GAME_TYPE.COMPETITIVE,
                game_size: GAME_SIZE.VS1,
            })
            await Server.partySearch({})
            changeView(VIEW.SEARCHING)
        } catch (e) {
            if (
                e.error === ERROR.SERVER_MAINTENANCE ||
                e.error === ERROR.CANCELATION_PENALTY
            ) {
                setGlobalState({ playerror: e })
            }
        }
    }

    return (
        <>
            <Lobby title="Play">
                <Container>
                    <ContainerTop>
                        <Option
                            title={translate('Tournaments')}
                            illustration="play-option-tutorial.png"
                            scale={0.9}
                            disabled={true}
                        />
                        <Option
                            title={translate('Competitive')}
                            description={translate('Ranked games')}
                            illustration="play-option-ranked.png"
                            scale={0.95}
                            trophies={user.trophies}
                            onClick={() => {
                                AUDIO.UNIT_SELECT()
                                onSearch()
                            }}
                        />
                        <Option
                            title={translate('Custom')}
                            description={translate('Invite your friend')}
                            illustration="play-option-custom.png"
                            scale={0.9}
                            tag={`${user.username}#${user.tagid}`}
                            onClick={() => {
                                AUDIO.OPEN()
                                setState({ inviting: true })
                            }}
                        />
                    </ContainerTop>
                    <ContainerBottom>
                        <Button
                            label={translate('Leaderboard')}
                            width={250}
                            height={60}
                            fontSize={25}
                            color={COLOR.BROWNDARK}
                            onClick={() => {
                                AUDIO.OPEN()
                                changeView(VIEW.LEADERBOARD)
                            }}
                        />
                    </ContainerBottom>
                </Container>
            </Lobby>
            {state.inviting && (
                <Invite
                    onClose={() => {
                        AUDIO.CLOSE()
                        setState({ inviting: false })
                    }}
                />
            )}
        </>
    )
}

function Option({
    title,
    description,
    illustration,
    trophies,
    tag,
    scale = 1,
    opacity = 1,
    disabled = false,
    onClick = () => {},
}) {
    return (
        <OptionContainer scale={scale} disabled={disabled} onPress={onClick}>
            <OptionBackground
                source={
                    UX_ASSETS[
                        disabled
                            ? 'bg-game-parchment2.png'
                            : 'bg-game-parchment.png'
                    ]
                }
                imageStyle={{ resizeMode: 'stretch' }}
            >
                <Title disabled={disabled} scale={scale}>
                    {title}
                </Title>
                <Ilustration
                    source={UX_ASSETS[illustration]}
                    opacity={opacity}
                    scale={scale}
                />
                {description && (
                    <Description scale={scale}>{description}</Description>
                )}

                {typeof trophies === 'number' && (
                    <Counter
                        value={trophies}
                        icon="icon-trophy.png"
                        scale={0.75}
                        align="center"
                    />
                )}

                {tag && (
                    <Tag>
                        <TagText>{tag}</TagText>
                    </Tag>
                )}
            </OptionBackground>
        </OptionContainer>
    )
}

const Container = styled.View`
    width: 100%;
    height: 100%;
`
const ContainerTop = styled.View`
    padding-top: ${px(25)};
    flex-direction: row;
    justify-content: center;
    align-items: center;
`
const ContainerBottom = styled.View`
    padding-top: ${px(10)};
    align-items: center;
`
const OptionContainer = styled.TouchableOpacity`
    width: ${(p) => px(550 * p.scale)};
    height: ${(p) => px(695 * p.scale)};
    margin: 0 ${px(10)};
    pointer-events: ${(p) => (p.disabled ? 'none' : 'auto')};
    // margin-top: ${(p) => px(-75 * p.scale)};
`
const OptionBackground = styled.ImageBackground`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
`
const Title = styled.Text`
    font-size: ${(p) => px(50 * p.scale)};
    margin-top: ${(p) => px(100 * p.scale)};
    font-family: ChangaOne;
    color: ${(p) => (p.disabled ? COLOR.DISABLED : COLOR.BROWN)};
`
const Ilustration = styled.Image`
    width: ${(p) => px(310 * p.scale)};
    height: ${(p) => px(242 * p.scale)};
    margin: ${(p) => px(20 * p.scale)} 0;
    opacity: ${(p) => p.opacity};
`
const Description = styled.Text`
    font-size: ${(p) => px(25 * p.scale)};
    font-family: Poppins-Medium;
    color: ${COLOR.BROWN};
    margin-bottom: ${(p) => px(45 * p.scale)};
`

const Tag = styled.View`
    border-radius: ${px(40)};
    background: ${COLOR.BROWNDARK};
    padding: ${px(8)} ${px(25)};
`

const TagText = styled.Text`
    font-size: ${px(16)};
    font-family: Poppins-Medium;
    color: ${COLOR.BEIGE};
`
