import React, { useEffect } from 'react'
import styled from '@emotion/native'
import { GAME_TYPE, GAME_RESULT } from 'conkis-core'
import { getLevel } from 'conkis-core/src/utils/xp'
import { UX_ASSETS, PROFILE_ICONS } from '~/const/assets'
import { COLOR, PADDING, MONTH } from '~/const'
import { AUDIO } from '~/audio'
import { useGlobalState, useLocalState } from '~/store/hooks'
import { Server } from '~/server'
import { translate } from '~/locale'
import { secondsToTime } from '~/utils'
import { px } from '~/utils/device'
import Lobby from '~/components/partials/Lobby'
import Stat from '~/components/stateless/Stat'
import Gradient from '~/components/stateless/Gradient'
import { H1 } from '~/components/stateless/Texts'
import ProfileIcon from '~/components/dialogs/ProfileIcon'

const SCALE_COUNTER = 1

export default function Profile() {
    const [{ user, profile_id }] = useGlobalState()
    const [state, setState] = useLocalState({
        loading: true,
        profile: { xp: 0 },
        profileicon_editing: false,
        games: [],
        games_page: 1,
        games_loading: false,
    })

    const { level, percent, xpnext } = getLevel(state.profile.xp)
    const progress = `${percent}%`
    const profile_is_me = !state.loading && user._id === state.profile._id

    useEffect(() => {
        ;(async () => {
            await fetchProfile()
            await fetchGames()
        })()
    }, [])

    async function onSelectProfileIcon(icon) {
        AUDIO.CLICK()
        await Server.userSetProfileIcon({ icon })
        setState({ profileicon_editing: false })
    }

    async function fetchProfile() {
        const profile = await Server.playerProfile({
            user_id: profile_id,
        })
        setState({ profile, loading: false })
    }

    async function fetchGames() {
        setState({
            games_loading: true,
        })
        const result = await Server.playerGames({
            user_id: profile_id,
            page: state.games_page,
        })
        setState({
            games: state.games.concat(result.games),
            games_page: state.games_page + 1,
            games_loading: false,
            games_more: result.more,
        })
    }

    return (
        <>
            <Lobby
                title={translate('Profile')}
                bgpattern="bg-weapons-white.png"
                bgcolors={[
                    [COLOR.BEIGE2, 0],
                    [COLOR.BEIGE4, 1],
                ]}
            >
                <ScrollView>
                    <Content>
                        <Left>
                            <LeftTop>
                                <ProfileContainer>
                                    <ProfileUserName
                                        source={UX_ASSETS['bg-diagonal.png']}
                                        imageStyle={{ resizeMode: 'repeat' }}
                                    >
                                        <ProfileName>
                                            {state.profile.username}
                                        </ProfileName>
                                        <ProfileTag>
                                            #{state.profile.tagid}
                                        </ProfileTag>
                                    </ProfileUserName>
                                    <ProfileProgress>
                                        <ProfileProgressBarContainer
                                            progress={progress}
                                        >
                                            <ProfileProgressBar>
                                                <Gradient
                                                    colors={[
                                                        ['#f9cbaf', 0.04],
                                                        ['#faaf80', 0.11],
                                                        ['#fb9b60', 0.5],
                                                        ['#e99663', 0.93],
                                                        ['#de8854', 1],
                                                    ]}
                                                />
                                            </ProfileProgressBar>
                                        </ProfileProgressBarContainer>
                                        <ProfileProgressData>
                                            <ProfileProgressDataLeft>
                                                {translate('Level')} {level}
                                            </ProfileProgressDataLeft>
                                            <ProfileProgressDataRight>
                                                <ProfileProgressDataRightText1>
                                                    {state.profile.xp}
                                                </ProfileProgressDataRightText1>
                                                <ProfileProgressDataRightText2>
                                                    {' '}
                                                    / {xpnext}
                                                </ProfileProgressDataRightText2>
                                            </ProfileProgressDataRight>
                                        </ProfileProgressData>
                                    </ProfileProgress>
                                    <ProfilePicContainer
                                        disabled={!profile_is_me}
                                        onPress={() => {
                                            AUDIO.CLICK()
                                            setState({
                                                profileicon_editing: true,
                                            })
                                        }}
                                    >
                                        <ProfilePicContainer2
                                            source={
                                                PROFILE_ICONS[
                                                    state.profile.icon
                                                ]
                                            }
                                        ></ProfilePicContainer2>
                                        {profile_is_me && (
                                            <ProfilePicContainerEdit>
                                                <ProfilePicContainerEditIcon
                                                    source={
                                                        UX_ASSETS[
                                                            'icon-edit.png'
                                                        ]
                                                    }
                                                />
                                            </ProfilePicContainerEdit>
                                        )}
                                    </ProfilePicContainer>
                                </ProfileContainer>
                            </LeftTop>
                            <LeftBottom>
                                <H1>{translate('Latest Games')}</H1>
                                {state.games.length > 0 && (
                                    <GameList>
                                        {state.games.map((game, key) => {
                                            const user_ids = Object.keys(
                                                game.users
                                            )
                                            const players = user_ids.map(
                                                (user_id) => {
                                                    const isme =
                                                        state.profile._id ===
                                                        user_id

                                                    const {
                                                        player_id,
                                                        team_id,
                                                    } = game.users[user_id]

                                                    const { trophies } =
                                                        game.users[user_id]
                                                            .before

                                                    const trophiesnew =
                                                        game.users[user_id]
                                                            .after.trophies -
                                                        trophies

                                                    const username =
                                                        game.board.state
                                                            .players[player_id]
                                                            .username

                                                    const units = Object.values(
                                                        game.board.state.units
                                                    ).filter((unit) =>
                                                        unit.players.includes(
                                                            player_id
                                                        )
                                                    )

                                                    const flags = Object.keys(
                                                        game.board.state.flags
                                                    ).filter(
                                                        (flag_id) =>
                                                            game.board.state
                                                                .flags[flag_id]
                                                                .team_id ===
                                                            team_id
                                                    )

                                                    return {
                                                        isme,
                                                        user_id,
                                                        player_id,
                                                        trophies,
                                                        trophiesnew,
                                                        username,
                                                        units,
                                                        flags,
                                                    }
                                                }
                                            )
                                            const player1 = players.find(
                                                (p) => p.isme
                                            )
                                            const player2 = players.find(
                                                (p) => !p.isme
                                            )

                                            const {
                                                created,
                                                type,
                                                turn,
                                                winner,
                                            } = game.board.state
                                            const date = new Date(created)
                                            const day = date.getDate()
                                            const month = MONTH[date.getMonth()]
                                            const year = date.getFullYear()
                                            const rounds = Math.ceil(turn / 2)
                                            const duration = secondsToTime(
                                                winner.time / 1000
                                            )
                                            const is_competitive =
                                                type === GAME_TYPE.COMPETITIVE

                                            return (
                                                <GameRow
                                                    key={key}
                                                    id={game._id.toString()}
                                                >
                                                    <GameLeft>
                                                        {is_competitive ? (
                                                            <ResultCompetitive
                                                                trophies={
                                                                    player1.trophiesnew
                                                                }
                                                            />
                                                        ) : (
                                                            <ResultCustom
                                                                player={player1}
                                                                board={
                                                                    game.board
                                                                }
                                                            />
                                                        )}
                                                    </GameLeft>
                                                    <GameSeparator
                                                        source={
                                                            UX_ASSETS[
                                                                'bg-gamelist.png'
                                                            ]
                                                        }
                                                    />
                                                    <GameRight>
                                                        <GameRightLeft>
                                                            <GamePlayerContainer>
                                                                <GamePlayer1Name>
                                                                    {
                                                                        player1.username
                                                                    }
                                                                </GamePlayer1Name>

                                                                {is_competitive && (
                                                                    <GamePlayerTrophies>
                                                                        <GamePlayerTrophiesIcon
                                                                            source={
                                                                                UX_ASSETS[
                                                                                    'icon-trophy2.png'
                                                                                ]
                                                                            }
                                                                        />
                                                                        <GamePlayerTrophiesValue>
                                                                            {
                                                                                player1.trophies
                                                                            }
                                                                        </GamePlayerTrophiesValue>
                                                                    </GamePlayerTrophies>
                                                                )}
                                                            </GamePlayerContainer>
                                                            <Vs>Vs</Vs>
                                                            <GamePlayerContainer>
                                                                <GamePlayer2Name>
                                                                    {
                                                                        player2.username
                                                                    }
                                                                </GamePlayer2Name>
                                                                {is_competitive && (
                                                                    <GamePlayerTrophies>
                                                                        <GamePlayerTrophiesIcon
                                                                            source={
                                                                                UX_ASSETS[
                                                                                    'icon-trophy2.png'
                                                                                ]
                                                                            }
                                                                        />
                                                                        <GamePlayerTrophiesValue>
                                                                            {
                                                                                player2.trophies
                                                                            }
                                                                        </GamePlayerTrophiesValue>
                                                                    </GamePlayerTrophies>
                                                                )}
                                                            </GamePlayerContainer>
                                                        </GameRightLeft>
                                                        <GameRightRight>
                                                            <GameRightCol>
                                                                <GamePropertyMedium>
                                                                    By
                                                                </GamePropertyMedium>
                                                                <GamePropertySmall>
                                                                    {winner.reason.slice(
                                                                        0,
                                                                        6
                                                                    )}
                                                                    .
                                                                </GamePropertySmall>
                                                            </GameRightCol>
                                                            <GameRightCol>
                                                                <GamePropertyMedium>
                                                                    Units
                                                                </GamePropertyMedium>
                                                                <GamePropertySmall>
                                                                    {`${player1.units.length} - ${player2.units.length}`}
                                                                </GamePropertySmall>
                                                            </GameRightCol>
                                                            <GameRightCol>
                                                                <GamePropertyMedium>
                                                                    Flags
                                                                </GamePropertyMedium>
                                                                <GamePropertySmall>
                                                                    {`${player1.flags.length} - ${player2.flags.length}`}
                                                                </GamePropertySmall>
                                                            </GameRightCol>

                                                            <GameRightCol>
                                                                <GamePropertyMedium>
                                                                    Rounds
                                                                </GamePropertyMedium>
                                                                <GamePropertySmall>
                                                                    {rounds}
                                                                </GamePropertySmall>
                                                            </GameRightCol>

                                                            <GameRightCol>
                                                                <GamePropertyMedium>
                                                                    Durat.
                                                                </GamePropertyMedium>
                                                                <GamePropertySmall>
                                                                    {duration}
                                                                </GamePropertySmall>
                                                            </GameRightCol>

                                                            <GameRightCol>
                                                                <GamePropertyMedium>
                                                                    {day}{' '}
                                                                    {translate(
                                                                        month
                                                                    ).slice(
                                                                        0,
                                                                        3
                                                                    )}
                                                                </GamePropertyMedium>
                                                                <GamePropertySmall>
                                                                    {year}
                                                                </GamePropertySmall>
                                                            </GameRightCol>
                                                        </GameRightRight>
                                                    </GameRight>
                                                </GameRow>
                                            )
                                        })}
                                    </GameList>
                                )}

                                {state.games_loading && (
                                    <GameListBottom>
                                        <GameListBottomInfo>
                                            <GameListTextInfo>
                                                {translate('Loading') + '...'}
                                            </GameListTextInfo>
                                        </GameListBottomInfo>
                                    </GameListBottom>
                                )}

                                {!state.games_loading && state.games_more && (
                                    <GameListBottom>
                                        <GameListLoadMore
                                            onPress={() => {
                                                fetchGames()
                                                AUDIO.CLICK()
                                            }}
                                        >
                                            <GameListTextInfo>
                                                {translate('Load more')}
                                            </GameListTextInfo>
                                        </GameListLoadMore>
                                    </GameListBottom>
                                )}

                                {state.games.length === 0 &&
                                    !state.games_loading && (
                                        <GameListBottom>
                                            <GameListBottomInfo>
                                                <GameListTextInfo>
                                                    {translate(
                                                        "${username} hasn't played any games yet",
                                                        state.profile
                                                    )}
                                                </GameListTextInfo>
                                            </GameListBottomInfo>
                                        </GameListBottom>
                                    )}
                            </LeftBottom>
                        </Left>

                        {!state.loading && (
                            <Right>
                                <RightTop>
                                    <RightTopColumn>
                                        <Stat
                                            label={translate('Trophies')}
                                            value={state.profile.trophies}
                                            icon="icon-trophy.png"
                                            scale={SCALE_COUNTER}
                                        />
                                        <Stat
                                            label={translate(
                                                'Highest Trophies'
                                            )}
                                            value={
                                                state.profile.trophies_highest
                                            }
                                            scale={SCALE_COUNTER}
                                        />
                                        <Stat
                                            label={translate('Games')}
                                            value={
                                                state.profile.wins +
                                                state.profile.draws +
                                                state.profile.loses
                                            }
                                            scale={SCALE_COUNTER}
                                        />
                                        {/* </RightTopColumn>
                                <RightTopColumn> */}
                                        <StatSeparator />
                                        <Stat
                                            label={translate('Wins')}
                                            value={state.profile.wins}
                                            scale={SCALE_COUNTER}
                                        />
                                        <Stat
                                            label={translate('Draws')}
                                            value={state.profile.draws}
                                            scale={SCALE_COUNTER}
                                        />
                                        <Stat
                                            label={translate('Loses')}
                                            value={state.profile.loses}
                                            scale={SCALE_COUNTER}
                                        />
                                        <StatSeparator />
                                        <Stat
                                            label={translate('Wins Week')}
                                            value={state.profile.wins_week}
                                            scale={SCALE_COUNTER}
                                        />
                                        <Stat
                                            label={translate('Draws Week')}
                                            value={state.profile.draws_week}
                                            scale={SCALE_COUNTER}
                                        />
                                        <Stat
                                            label={translate('Loses Week')}
                                            value={state.profile.loses_week}
                                            scale={SCALE_COUNTER}
                                        />
                                        <StatSeparator />
                                        <Stat
                                            label={translate('Cancels')}
                                            value={state.profile.cancels_total}
                                            scale={SCALE_COUNTER}
                                        />
                                    </RightTopColumn>
                                </RightTop>
                            </Right>
                        )}
                    </Content>
                </ScrollView>
            </Lobby>
            {state.profileicon_editing && (
                <ProfileIcon
                    onSelect={onSelectProfileIcon}
                    onClose={() => {
                        AUDIO.CLOSE()
                        setState({ profileicon_editing: false })
                    }}
                />
            )}
        </>
    )
}

function ResultCompetitive({ trophies }) {
    const value = trophies > 0 ? `+${trophies}` : trophies
    const color =
        trophies === 0 ? COLOR.BEIGE4 : trophies > 0 ? COLOR.GREEN : COLOR.RED
    return <Result value={value} color={color} />
}

function ResultCustom({ board, player }) {
    const { players, winner } = board.state
    const { team_id } = players[player.player_id]
    const result =
        winner.team_id === null
            ? GAME_RESULT.DRAW
            : winner.team_id === team_id
            ? GAME_RESULT.VICTORY
            : GAME_RESULT.DEFEAT

    const color =
        result === GAME_RESULT.DRAW
            ? COLOR.BEIGE4
            : result === GAME_RESULT.VICTORY
            ? COLOR.GREEN
            : COLOR.RED

    return <Result value={translate(result)} color={color} showicon={false} />
}

function Result({ value, color, showicon = true }) {
    return (
        <ResultContainer>
            {/* <ResultType>{label}</ResultType> */}
            <ResultTrophies showicon={showicon}>
                {showicon && <Icon source={UX_ASSETS['icon-trophy.png']} />}
                <ResultValue color={color}>{value}</ResultValue>
            </ResultTrophies>
        </ResultContainer>
    )
}

const ScrollView = styled.ScrollView``

const Content = styled.View`
    padding: ${px(75)} ${px(PADDING.SIDES)};
    flex-direction: row;
`

const Left = styled.View`
    width: 65%;
    // padding-left: ${px(50)};
`
const LeftTop = styled.View``

const LeftBottom = styled.View`
    margin-top: ${px(75)};
`

const ProfileContainer = styled.View`
    padding-left: ${px(150 / 2)};
`
const ProfileUserName = styled.ImageBackground`
    height: ${px(130)};
    border-radius: ${px(10)} ${px(10)} ${px(50)} ${px(10)};
    padding-left: ${px(200 / 2 + 40)};
    background: ${COLOR.BROWNDARK};
    border: ${px(10)} solid ${COLOR.BROWN};
    flex-direction: row;
    align-items: center;
    box-shadow: 0 0 10px white;
    overflow: hidden;
`
const ProfileName = styled.Text`
    font-family: Poppins-Black;
    font-size: ${px(50)};
    color: ${COLOR.BEIGE};
`
const ProfileTag = styled.Text`
    font-family: Poppins-Black;
    font-size: ${px(25)};
    color: ${COLOR.BEIGE};
    padding-top: ${px(10)};
    opacity: 0.6;
`

const ProfileProgress = styled.View`
    margin-left: ${px(50)};
    background: ${COLOR.BEIGE};
    border-radius: ${px(50)};
    height: ${px(50)};
    width: 85%;
`

const ProfileProgressBarContainer = styled.View`
    width: ${(p) => p.progress};
    height: 100%;
    padding: ${px(8)};
`

const ProfileProgressBar = styled.View`
    flex: 1;
    border-radius: ${px(50)};
    overflow: hidden;
`

const ProfileProgressData = styled.View`
    position: absolute;
    width: 100%;
    height: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: ${px(75)};
    padding-right: ${px(20)};
`

const ProfileProgressDataLeft = styled.Text`
    margin-top: ${px(-3)};
    font-family: ChangaOne;
    font-size: ${px(24)};
    // text-shadow: 0 ${px(2)} 0 rgba(0, 0, 0, 0.2);
    color: ${COLOR.BROWN};
    // opacity: 0.3;
`
const ProfileProgressDataRight = styled.View`
    flex-direction: row;
`
const ProfileProgressDataRightText1 = styled.Text`
    font-family: Poppins-Black;
    font-size: ${px(18)};
    color: ${COLOR.BROWN};
`
const ProfileProgressDataRightText2 = styled.Text`
    font-family: Poppins-Black;
    font-size: ${px(18)};
    color: ${COLOR.BROWN};
    opacity: 0.3;
`

const ProfilePicContainer = styled.TouchableOpacity`
    width: ${px(200)};
    height: ${px(200)};
    border-radius: ${px(200)};
    left: ${px(0)};
    top: ${px(-10)};
    position: absolute;
    border-width: ${px(10)};
    border-color: ${COLOR.BEIGE};
    box-shadow: 0 ${px(8)} 5px rgba(0, 0, 0, 0.1);
    background: ${COLOR.DISABLED2};
`

const ProfilePicContainerEdit = styled.View`
    position: absolute;
    width: ${px(65)};
    height: ${px(65)};
    border-radius: ${px(65)};
    background: ${COLOR.BEIGE};
    left: ${px(-15)};
    top: ${px(-15)};
    align-items: center;
    justify-content: center;
`

const ProfilePicContainerEditIcon = styled.Image`
    width: ${px(45)};
    height: ${px(45)};
`

const ProfilePicContainer2 = styled.Image`
    width: ${px(180)};
    height: ${px(180)};
    border-radius: ${px(180)};
`

const Right = styled.View`
    flex: 1;
`

const RightTop = styled.View`
    flex-direction: row;
`
const RightTopColumn = styled.View`
    margin-left: ${px(70)};
    flex: 1;
`

const GameList = styled.View`
    margin-top: ${px(10)};
`

const GameListBottom = styled.View`
    margin-top: ${px(10)};
`

const GameListBottomInfo = styled.View`
    justify-content: center;
    align-items: center;
    border-radius: ${px(8)};
    height: ${px(80)};
    border: ${px(4)} solid transparent;
`

const GameListLoadMore = styled.TouchableOpacity`
    justify-content: center;
    align-items: center;
    border-radius: ${px(8)};
    height: ${px(80)};
    border: ${px(4)} dashed ${COLOR.BEIGE};
`

const GameListTextInfo = styled.Text`
    font-family: Poppins-Black;
    font-size: ${px(22)};
    color: ${COLOR.BROWNDARK};
    opacity: 0.5;
`

// TouchableOpacity
const GameRow = styled.View`
    flex-direction: row;
    border-radius: ${px(8)};
    height: ${px(80)};
    background: ${COLOR.BEIGE};
    overflow: hidden;
    margin-bottom: ${px(3)};
`
const GameLeft = styled.View`
    width: ${px(130)};
    padding-left: ${px(10)};
    height: 100%;
    background: ${COLOR.BEIGE2};
    flex-direction: row;
    align-items: center;
    justify-content: center;
`
const GameSeparator = styled.Image`
    width: ${px(16)};
    height: ${px(77)};
`

const GameRight = styled.View`
    flex-direction: row;
    justify-content: space-between;
    flex: 1;
`
const GameRightLeft = styled.View`
    flex-direction: row;
    align-items: center;
    padding-left: ${px(40)};
`
const GameRightRight = styled.View`
    flex-direction: row;
    align-items: center;
    justify-content: center;
`
const GameRightCol = styled.View`
    align-items: center;
    width: ${px(80)};
    border: ${px(1)} dashed ${COLOR.BEIGE2};
    border-right-width: 0;
    border-top-width: 0;
    border-bottom-width: 0;
`

const GamePropertyMedium = styled.Text`
    font-family: Poppins-Bold;
    font-size: ${px(16)};
    line-height: ${px(18)};
    color: ${COLOR.BEIGE4};
`
const GamePropertySmall = styled.Text`
    font-family: Poppins-Medium;
    font-size: ${px(16)};
    color: ${COLOR.BEIGE4};
`

const GamePlayerContainer = styled.View``
const GamePlayerTrophies = styled.View`
    flex-direction: row;
    // justify-content: space-between;
    align-items: center;
    // background: red;
`

const GamePlayerTrophiesIcon = styled.Image`
    width: ${px(16)};
    height: ${px(16)};
`
const GamePlayerTrophiesValue = styled.Text`
    font-family: Poppins-SemiBold;
    color: ${COLOR.BEIGE4};
    font-size: ${px(18)};
    // line-height: ${px(22)};
    padding-left: ${px(5)};
`
const GamePlayerBot = styled.Text`
    font-family: Poppins-SemiBold;
    color: ${COLOR.BEIGE3};
    font-size: ${px(20)};
    opacity: 0.5;
`

const GamePlayer1Name = styled.Text`
    font-family: Poppins-Black;
    font-size: ${px(22)};
    line-height: ${px(22)};
    color: ${COLOR.BROWN};
`

const GamePlayer2Name = styled.Text`
    font-family: Poppins-Medium;
    font-size: ${px(22)};
    line-height: ${px(22)};
    color: ${COLOR.BROWN};
`

const Vs = styled.Text`
    font-family: ChangaOne;
    color: ${COLOR.BEIGE3};
    font-size: ${px(25)};
    padding: 0 ${px(15)};
`

const Icon = styled.Image`
    width: ${px(28)};
    height: ${px(28)};
    position: absolute;
    left: ${px(-10)};
`

const ResultContainer = styled.View`
    justify-content: center;
    align-items: center;
`

const ResultTrophies = styled.View`
    flex-direction: row;
    background: ${COLOR.BEIGE};
    justify-content: ${(p) => (p.showicon ? 'flex-end' : 'center')};
    align-items: center;
    min-width: ${px(90)};
    border-radius: ${px(5)};
`

const ResultType = styled.Text`
    font-family: Poppins-Bold;
    color: ${COLOR.BEIGE4};
    font-size: ${px(16)};
    line-height: ${px(18)};
`

const ResultValue = styled.Text`
    font-family: Poppins-Bold;
    text-align: right;
    font-size: ${px(22)};
    padding: 0 ${px(10)};
    color: ${(p) => p.color};
`

const StatSeparator = styled.View`
    height: ${px(40)};
`
