import React, { useState } from 'react'
import styled from '@emotion/native'
import { getLevel } from 'conkis-core/src/utils/xp'
import { changeView, openWeb3 } from '~/store'
import { AUDIO } from '~/audio'
import { useGlobalState } from '~/store/hooks'
import { PADDING, COLOR, VIEW } from '~/const'
import { UX_ASSETS, PROFILE_ICONS } from '~/const/assets'
import { px } from '~/utils/device'
import Counter from '~/components/stateless/Counter'
import Player from '~/components/stateless/Player'
import Settings from '~/components/dialogs/Settings'

export default React.memo(function ({ title, back = VIEW.HOME }) {
    const [{ user, viewback }] = useGlobalState(({ path }) => {
        return ['user'].includes(path[0])
    })
    const [show_settings, setShowSettings] = useState(false)
    const { level, percent } = getLevel(user.xp)

    function onCloseSettings() {
        AUDIO.CLOSE()
        setShowSettings(false)
    }

    return (
        <>
            <Background
                source={UX_ASSETS['header-bg.png']}
                imageStyle={{ resizeMode: 'stretch' }}
            >
                <Content>
                    <Left>
                        {title !== undefined ? (
                            <Back
                                onPress={() => {
                                    AUDIO.CLOSE()
                                    changeView(
                                        viewback !== null ? viewback : back
                                    )
                                }}
                            >
                                <Icon source={UX_ASSETS['icon-back.png']} />
                                <BackLabel>{title}</BackLabel>
                            </Back>
                        ) : (
                            <Player
                                username={user.username}
                                trophies={user.trophies}
                                level={level}
                                progress={percent}
                                icon={user.icon}
                                onPress={() => {
                                    AUDIO.OPEN()
                                    changeView(VIEW.PROFILE, {
                                        profile_id: user._id,
                                    })
                                }}
                            />
                        )}
                    </Left>
                    {/* <Center>
                    <Logo source={UX_ASSETS['logo.png']} />
                </Center> */}
                    <Right>
                        <RightItemNonTouchable>
                            <Counter
                                value={user.tulips}
                                icon="icon-tulip.png"
                            />
                        </RightItemNonTouchable>

                        <RightItemNonTouchable>
                            <Counter value={user.coins} icon="icon-coin.png" />
                        </RightItemNonTouchable>

                        <RightItem
                            onPress={() => {
                                AUDIO.OPEN()
                                openWeb3()
                            }}
                        >
                            <Icon source={UX_ASSETS['icon-wallet.png']} />
                        </RightItem>

                        <RightItem
                            onPress={() => {
                                AUDIO.OPEN()
                                setShowSettings(true)
                            }}
                        >
                            <Icon source={UX_ASSETS['icon-settings.png']} />
                        </RightItem>
                    </Right>
                </Content>
            </Background>
            {show_settings && (
                <Settings
                    onClose={onCloseSettings}
                    onBackground={onCloseSettings}
                />
            )}
        </>
    )
})

const Background = styled.ImageBackground`
    width: 100%;
    height: ${px(140)};
`

const Content = styled.View`
    height: ${px(120)};
    flex-direction: row;
    padding: 0 ${px(PADDING.SIDES)};
`

const Left = styled.View`
    flex: 1;
    justify-content: center;
`

const Right = styled.View`
    flex: 1;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
`

// REMOVE THIS AND REUSE RightItem WHEN NEED IT
const RightItemNonTouchable = styled.View`
    padding: 0 ${px(30)};
    right: ${px(-30)};
    height: 100%;
    justify-content: center;
`
// REMOVE THIS AND REUSE RightItem WHEN NEED IT

const RightItem = styled.Pressable`
    padding: 0 ${px(30)};
    right: ${px(-30)};
    height: 100%;
    justify-content: center;
`

/// Back button
const Back = styled.TouchableOpacity`
    flex-direction: row;
    align-items: center;
    height: 100%;
`
const BackLabel = styled.Text`
    top: ${px(-8)};
    font-family: ChangaOne;
    font-size: ${px(75)};
    letter-spacing: ${px(1)};
    color: ${COLOR.BEIGE};
    position: relative;
    text-shadow: 0 ${px(10)} 0 rgba(0, 0, 0, 0.25);
    margin-left: ${px(20)};
`

const Icon = styled.Image`
    width: ${px(55)};
    height: ${px(55)};
`
