import React from 'react'
import styled from '@emotion/native'
import { SHOP_CATEGORY, CARDS, REWARD_ASSET } from 'conkis-core'
import { UX_ASSETS } from '~/const/assets'
import { COLOR, PADDING } from '~/const'
import { useGlobalState, useLocalState, useServer } from '~/store/hooks'
import { translate } from '~/locale'
import { px } from '~/utils/device'
import { AUDIO } from '~/audio'
import { secondsToTimeRaw } from '~/utils'
import Counter from '~/components/stateless/Counter'
import RewardIcon from '~/components/stateless/RewardIcon'
import ShopItem from '~/components/dialogs/ShopItem'
import Payment from '~/components/dialogs/Payment'
import CardInfo from '~/components/dialogs/CardInfo'
import Lobby from '~/components/partials/Lobby'
import {
    TabsHeader,
    Tabs,
    Tab,
    TabText,
    TabsInfo,
    TabsInfoText,
} from '~/components/stateless/Tabs'

export default function Shop() {
    const [{ user }] = useGlobalState({})
    const [state, setState] = useLocalState({
        category: SHOP_CATEGORY.DAILY,
        item: null,
        payment: false,
        cardinfo: null,
    })
    const [shop] = useServer({ items: [] }, 'shopGet')
    const [cards] = useServer([], 'userCards', { user_id: user._id })

    function onCategory(category) {
        AUDIO.CLICK()
        setState({ category })
    }

    function onClickItem(item) {
        AUDIO.OPEN()
        setState({ item })
    }

    function onCardInfo(item) {
        AUDIO.OPEN()
        setState({ cardinfo: item })
    }

    function onPayment() {
        AUDIO.CLICK()
        setState({ payment: true })
    }

    function onCloseItem() {
        AUDIO.CLOSE()
        setState({ item: null, payment: false })
    }

    function onCloseCardInfo() {
        AUDIO.CLOSE()
        setState({ cardinfo: null })
    }

    return (
        <>
            <Lobby title={translate('Shop')}>
                <ScrollView>
                    <Content>
                        <TabsHeader>
                            <Tabs>
                                <Tab
                                    selected={
                                        state.category === SHOP_CATEGORY.DAILY
                                    }
                                    onPress={() =>
                                        onCategory(SHOP_CATEGORY.DAILY)
                                    }
                                >
                                    <TabText
                                        selected={
                                            state.category ===
                                            SHOP_CATEGORY.DAILY
                                        }
                                    >
                                        {translate('Daily Offers')}
                                    </TabText>
                                </Tab>
                                <Tab
                                    selected={
                                        state.category === SHOP_CATEGORY.CARDS
                                    }
                                    onPress={() =>
                                        onCategory(SHOP_CATEGORY.CARDS)
                                    }
                                >
                                    <TabText
                                        selected={
                                            state.category ===
                                            SHOP_CATEGORY.CARDS
                                        }
                                    >
                                        {translate('All Cards')}
                                    </TabText>
                                </Tab>
                                <Tab
                                    selected={
                                        state.category ===
                                        SHOP_CATEGORY.TULIPS_COINS
                                    }
                                    onPress={() =>
                                        onCategory(SHOP_CATEGORY.TULIPS_COINS)
                                    }
                                >
                                    <TabText
                                        selected={
                                            state.category ===
                                            SHOP_CATEGORY.TULIPS_COINS
                                        }
                                    >
                                        {`${translate('Tulips')} & ${translate(
                                            'Coins'
                                        )}`}
                                    </TabText>
                                </Tab>
                            </Tabs>
                            {/* <TabsRight>
                                {state.category === SHOP_CATEGORY.DAILY && (
                                    <TabsInfo>
                                        <TabsRightInfoText>
                                            {translate(
                                                'These daily offers ends in ${h}h ${m}m',
                                                { h: 14, m: 28 }
                                            )}
                                        </TabsRightInfoText>
                                    </TabsInfo>
                                )}
                            </TabsRight> */}
                        </TabsHeader>
                        {state.category === SHOP_CATEGORY.DAILY && (
                            <CategoryContent>
                                <ItemList
                                    onClickItem={onClickItem}
                                    items={shop.items
                                        .filter(
                                            (item) =>
                                                item.category ===
                                                SHOP_CATEGORY.DAILY
                                        )
                                        .sort((a, b) => b.rarity - a.rarity)
                                        .sort(
                                            (a, b) => b.offer.off - a.offer.off
                                        )}
                                />
                            </CategoryContent>
                        )}
                        {state.category === SHOP_CATEGORY.CARDS && (
                            <CategoryContent>
                                <ItemList
                                    onClickItem={onClickItem}
                                    items={shop.items
                                        .filter(
                                            (item) =>
                                                item.category ===
                                                SHOP_CATEGORY.CARDS
                                        )
                                        .sort((a, b) => a.power - b.power)
                                        .sort((a, b) => a.rarity - b.rarity)}
                                />
                            </CategoryContent>
                        )}
                        {state.category === SHOP_CATEGORY.TULIPS_COINS && (
                            <CategoryContent>
                                <ItemList
                                    onClickItem={onClickItem}
                                    items={shop.items.filter(
                                        (item) =>
                                            item.category ===
                                            SHOP_CATEGORY.TULIPS_COINS
                                    )}
                                />
                            </CategoryContent>
                        )}
                    </Content>
                </ScrollView>
            </Lobby>
            {state.item !== null && !state.payment && (
                <ShopItem
                    item={state.item}
                    user={user}
                    cards={cards}
                    onPayment={onPayment}
                    onCardInfo={onCardInfo}
                    onClose={onCloseItem}
                />
            )}

            {state.item !== null && state.payment && (
                <Payment item={state.item} onClose={onCloseItem} />
            )}

            {state.cardinfo !== null && (
                <CardInfo card={state.cardinfo} onClose={onCloseCardInfo} />
            )}
        </>
    )
}

function ItemList({ items, onClickItem }) {
    return items.map((item) => {
        return (
            <Item key={item.id} onPress={() => onClickItem(item)}>
                <ItemBackground source={UX_ASSETS['shop-item-background.png']}>
                    <ItemIconContainer>
                        <RewardIcon
                            size={250}
                            asset={item.asset}
                            card={item.type}
                            rarity={item.rarity}
                        />
                    </ItemIconContainer>

                    <ItemLabelContainer>
                        <ItemLabel>
                            {item.asset === REWARD_ASSET.CARD
                                ? translate(CARDS[item.type].title)
                                : `x${item.amount} ${
                                      item.asset === REWARD_ASSET.TULIPS
                                          ? translate('Tulips')
                                          : translate('Coins')
                                  }`}
                        </ItemLabel>
                    </ItemLabelContainer>
                    {item.offer !== undefined && (
                        <ItemHeader>
                            <ItemTime>
                                <ItemTimeText>
                                    {translate(
                                        '${h}h ${m}m',
                                        secondsToTimeRaw(
                                            (item.offer.ends - item.offer.now) /
                                                1000
                                        )
                                    )}
                                </ItemTimeText>
                            </ItemTime>
                            <ItemOff source={UX_ASSETS['shop-off.png']}>
                                <ItemOffText>-${item.offer.off}%</ItemOffText>
                            </ItemOff>
                        </ItemHeader>
                    )}
                    <ItemFooter>
                        <ItemFooterContent>
                            {item.tulips !== undefined && (
                                <ItemFooterCounter>
                                    <Counter
                                        value={item.tulips}
                                        icon={`icon-tulip.png`}
                                        width={140}
                                        scale={0.9}
                                        rounded={true}
                                        align="center"
                                        color={COLOR.BROWNBLACK}
                                        shadow="0 5px 10px rgba(0, 0, 0, 0.5)"
                                    />
                                </ItemFooterCounter>
                            )}

                            {item.coins !== undefined && (
                                <ItemFooterCounter>
                                    <Counter
                                        value={item.coins}
                                        icon={`icon-coin.png`}
                                        width={140}
                                        scale={0.9}
                                        rounded={true}
                                        align="center"
                                        color={COLOR.BROWNBLACK}
                                        shadow="0 5px 10px rgba(0, 0, 0, 0.5)"
                                    />
                                </ItemFooterCounter>
                            )}

                            {item.usd !== undefined && (
                                <ItemFooterCounter>
                                    <Counter
                                        value={`${item.usd}`}
                                        icon={`icon-dollar.png`}
                                        width={140}
                                        scale={0.9}
                                        rounded={true}
                                        align="center"
                                        color={COLOR.BROWNBLACK}
                                        shadow="0 5px 10px rgba(0, 0, 0, 0.5)"
                                    />
                                </ItemFooterCounter>
                            )}
                        </ItemFooterContent>
                    </ItemFooter>
                </ItemBackground>
            </Item>
        )
    })
}

const ScrollView = styled.ScrollView``

const Content = styled.View`
    padding: ${px(50)} ${px(PADDING.SIDES)};
`

const CategoryContent = styled.View`
    margin-top: ${px(50)};
    flex-direction: row;
    flex-wrap: wrap;
    // display: grid;
    // grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    // gap: ${px(40)};
`

const Item = styled.TouchableOpacity`
    margin-bottom: ${px(50)};
    margin-right: ${px(50)};
    width: ${px(290)};
    height: ${px(400)};
`

const ItemBackground = styled.ImageBackground`
    width: 100%;
    height: 100%;
    align-items: center;
`

const ItemIconContainer = styled.View`
    width: 100%;
    height: 90%;
    align-items: center;
    justify-content: center;
    // background: blue;
`

const ItemLabelContainer = styled.View`
    width: 90%;
    height: ${px(120)};
    bottom: 0;
    // background: red;
    justify-content: center;
    position: absolute;
`

const ItemLabel = styled.Text`
    font-family: Poppins-Black;
    color: ${COLOR.BROWNDARK};
    font-size: ${px(20)};
    line-height: ${px(24)};
    text-align: center;
`

const ItemHeader = styled.View`
    position: absolute;
    width: 100%;
    top: ${px(-5)};
    flex-direction: row;
    justify-content: space-between;
`
const ItemTime = styled.View`
    height: ${px(40)};
    background: ${COLOR.BROWNBLACK};
    align-items: center;
    justify-content: center;
    padding: ${px(15)} ${px(20)};
    border-radius: ${px(30)};
    // border: ${px(5)} solid ${COLOR.BROWNBLACK};
    left: ${px(-10)};
`
const ItemTimeText = styled.Text`
    font-family: Poppins-Bold;
    font-size: ${px(16)};
    line-height: ${px(16)};
    color: ${COLOR.BEIGE};
`
const ItemOff = styled.ImageBackground`
    width: ${px(88)};
    height: ${px(50)};
    align-items: flex-end;
    transform: rotate(15deg);
    right: ${px(-10)};
`
const ItemOffText = styled.Text`
    font-family: Poppins-Bold;
    font-size: ${px(18)};
    color: ${COLOR.BEIGE};
    margin-right: ${px(10)};
    margin-top: ${px(8)};
`

const ItemFooter = styled.View`
    position: absolute;
    width: 100%;
    align-items: center;
    bottom: ${px(-20)};
`

const ItemFooterContent = styled.View`
    height: ${px(50)};
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    // background: red;
`

const ItemFooterCounter = styled.View`
    margin: 0 ${px(20)};
`
